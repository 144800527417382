import styled from 'styled-components'
import { CloseablePageTemplage } from '../../templates'
import { brandTheme, screen } from '../../../theme'
import { AntdSelect, Button, Icon, Input, SelectInput, Spinner, Text } from '../../atoms'
import { africredColors } from '../../../theme/colors'
import access from '../../../assets/pngs/partners/access.png'
import fcmb from '../../../assets/pngs/partners/fcmb.png'
import gtb from '../../../assets/pngs/partners/gtb.png'
import lendwise from '../../../assets/pngs/partners/lendwise.png'
import polaris from '../../../assets/pngs/partners/polaris.png'
import power from '../../../assets/pngs/partners/power.png'
import stilt from '../../../assets/pngs/partners/stilt.png'
import {
    DragAndDropFiles,
    InputWithAddonSelect,
    OnboardingFlow,
    StepProgressIndicator,
} from '../../molecules'
import { useEffect, useState } from 'react'
import useAuth from '../../../hooks/useAuth'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { toast } from 'react-toastify'
import {
    formatNumber,
    formatSelectOptionForAntd,
    getCountryCode,
    separatePhoneNumberString,
} from '../../../utils'
import { PricingConversionBreakdown } from '../../molecules/PricingConversionBreakdown'
import { CountryDropdown } from '../../molecules/CountryDropdown';
import { useNavigate } from 'react-router-dom';

const FormSection = styled.section`
    background-color: ${brandTheme.brand.backgroundSkin4};
    border-radius: 16px;
    padding: 48px 24px;
    height: fit-content;

    @media only screen and (${screen.md}) {
        padding: 48px;
    }
`
const Wrapper = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    padding: ${({ padding }) => padding || '18px 16px'};
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: ${({ borderradius }) => borderradius || '16px'};
    /* background-color: ${({ bgcolor }) =>
        bgcolor || africredColors.neutrals.night['100']}; */
    border: 1px solid #d1d1d1;
`
const TitleWrapper = styled.div`
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 32px;
`
const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
`
const GridItem = styled.div`
    display: flex;
    padding: 16px;
    gap: 10px;
    border-radius: 16px;
    background: ${africredColors.primary.green[50]};
`
const FormInputs = styled.div`
    display: flex;
    gap: 20px;
    flex-direction: column;
`
const FormRow = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    > div {
        width: 100%;
    }

    @media only screen and (${screen.md}) {
        flex-direction: row;
    }
`

export const Remittance = () => {
    const auth = useAuth()
    const navigate = useNavigate()
    const isStudent = auth?.accountType === 'Student/Guardian'
    const userCountry = auth?.address?.country
    const userCountryCode = getCountryCode(userCountry)
    const userPhoneNumber =
        auth?.phoneNumber?.code.slice(1) + auth?.phoneNumber?.number

    const [onboardingData, setOnboardingData] = useState({
        loading: false,
        email: isStudent ? auth.email : '',
        firstName: isStudent ? auth.firstName : '',
        lastName: isStudent ? auth.lastName : '',
        phoneNumber: isStudent ? userPhoneNumber : '',
        countryCode: isStudent ? userCountryCode : '',
        cityOfResidence: isStudent ? auth?.address?.city : '',
        countryOfStudy: isStudent ? auth?.address?.country : '',
        amountToRemit: '',
        amountToRemitInCurrency: '',
        remittanceProcessingFeeInCurrency: '',
        currency: 'USD',
        currencyToPay: 'EUR',
        currencyToPayIcon:
            'https://afri-cred.s3.eu-west-2.amazonaws.com/flags/Richoco+Studio++Samples/EU+-+Europe.png',
        remittanceProcessingFee: '',
        purpose: '',
        schoolOfferLetter: null,
        proofOfIdentity: null,
        paymentStatus: 'pending',
    })
    const [currentIndex, setCurrentIndex] = useState(0)
    const [stepCount, setStepCount] = useState(null)
    const axiosPrivate = useAxiosPrivate()

    const onNext = (stepData, numberOfSteps) => {
        setOnboardingData({ ...onboardingData, ...stepData })
        if (currentIndex < numberOfSteps) setCurrentIndex(currentIndex + 1)
    }

    const goBack = () => {
        if (currentIndex > 0) setCurrentIndex(currentIndex - 1)
        else navigate(-1)
    }

    const onFinish = async data => {
        if (data.loading) return

        setOnboardingData(prev => ({
            ...prev,
            loading: true,
        }))

        data.amountToRemit = Number(data.amountToRemit);
        data.amountToRemitInCurrency = Number(data.amountToRemitInCurrency);

        const payload = {
            ...data,
            amountToRemit: data?.amountToRemit,
            purpose: data.purpose,
            schoolOfferLetter: data?.schoolOfferLetter?.id,
            proofOfIdentity: {
                typeOfProofOfIdentity:
                    data?.proofOfIdentity?.typeOfProofOfIdentity,
                file: data?.proofOfIdentity?.id,
            },
            paymentStatus: 'pending',
            phoneNumber: separatePhoneNumberString(
                data?.phoneNumber,
                data?.countryCode,
            ),
        }

        if (isStudent) {
            payload.phoneNumber = auth?.phoneNumber;
        }
        
        try {
            if (
                data?.amountToRemitInCurrency
            ) {
                const prepareAmount = Number((Number(data.amountToRemitInCurrency) * 100).toFixed(2));
                const initPaymentRes = await axiosPrivate.post(
                    '/payment/create-checkout-session',
                    {
                        email: data.email,
                        amount: prepareAmount,
                        transactionType: 'Remittance',
                        name: 'Remittance',
                        currency: data?.currencyToPay,
                    },
                )

                if (initPaymentRes?.data) {
                    const paymentInfo = initPaymentRes.data.paymentIntentLink;

                    let dataClone = { ...payload }

                    if (
                        !dataClone?.proofOfIdentity ||
                        !dataClone?.proofOfIdentity?.typeOfProofOfIdentity ||
                        !dataClone?.proofOfIdentity?.file
                    ) {
                        delete dataClone?.proofOfIdentity
                    }
                    if (!dataClone?.schoolOfferLetter) {
                        delete dataClone.schoolOfferLetter
                    }

                    const newPayload = {
                        email: dataClone?.email,
                        firstName: dataClone?.firstName,
                        lastName: dataClone?.lastName,
                        phoneNumber: dataClone?.phoneNumber,
                        cityOfResidence: dataClone?.cityOfResidence,
                        countryOfStudy: dataClone?.countryOfStudy,
                        // admissionStatus:dataClone?.admissionStatus,
                        purpose: dataClone?.purpose,
                        paymentStatus: dataClone?.paymentStatus,
                        currency: dataClone?.currency,
                        currencyToPay: dataClone?.currencyToPay,
                        amountToRemit: dataClone?.amountToRemit,
                        // amountInDollars:dataClone?.amountInDollars,
                        // amountInNaira:dataClone?.amountInNaira,
                        amountToRemitInCurrency:
                            dataClone?.amountToRemitInCurrency,
                        // processingFeeInNaira:dataClone?.processingFeeInNaira,
                        remittanceProcessingFeeInCurrency:
                            dataClone?.remittanceProcessingFeeInCurrency,
                        remittanceProcessingFee:
                            dataClone?.remittanceProcessingFee,
                        // processingFeeInCurrency: dataClone?.processingFeeInCurrency,
                    }
                    if (dataClone.proofOfIdentity) {
                        newPayload.proofOfIdentity = dataClone?.proofOfIdentity
                    }
                    if (dataClone?.schoolOfferLetter) {
                        newPayload.schoolOfferLetter =
                            dataClone?.schoolOfferLetter
                    }
                    // console.log("PAYSTACKREFERENCE NUMBER:::::::: PROOF OF FUNDS", {payStackReferenceNumber: initPaymentRes.data.data.reference,})

                    const response = await axiosPrivate.post(
                        '/remittance/create',
                        {
                            ...newPayload,
                            payStackReferenceNumber:
                                paymentInfo.id,
                        },
                    )

                    if (response?.data) {
                        window.location = initPaymentRes?.data?.data?.authorization_url;
                    }
                }
            } else {
                toast.error(
                    'Confirm that you have specified an amount to remit',
                )
            }
        } catch (e) {
            toast.error(e?.response?.data.message)
        }
        
        setOnboardingData(prev => ({
            ...prev,
            loading: false,
        }))
    }

    return (
        <CloseablePageTemplage>
            <TitleWrapper>
                <Text
                    textalign="center"
                    weight="medium"
                    type="h2"
                    color="black"
                >
                    Send Money Globally
                </Text>

                <Text textalign="center" type="p">
                    We've designed our Remittance Form with your convenience in
                    mind.
                </Text>
            </TitleWrapper>

            <div className="flex items-center mt-4 mb-8">
                <StepProgressIndicator
                    numberOfSteps={stepCount}
                    currentStep={currentIndex + 1}
                />
            </div>

            <OnboardingFlow
                currentIndex={currentIndex}
                onFinish={onFinish}
                onNext={onNext}
                getStepCount={setStepCount}
                goBack={goBack}
            >
                {!isStudent && (
                    <StepOne
                        data={onboardingData}
                        setData={setOnboardingData}
                    />
                )}
                <StepTwo data={onboardingData} setData={setOnboardingData} />
                <StepThree data={onboardingData} setData={setOnboardingData} />
                {/* <StepFour data={onboardingData} setData={setOnboardingData} /> */}
                <StepFive data={onboardingData} setData={setOnboardingData} />
            </OnboardingFlow>
        </CloseablePageTemplage>
    )
}

const StepFooter = ({ goToNext, className, goBack, lastStep, loading }) => {
    return (
        <div
            className={`flex justify-between w-full sm:w-2/3 gap-4 rounded pt-8 pb-8 ${
                className || 'lg:w-2/4 2xl:w-2/5'
            }`}
        >
            <Button
                onClick={goBack}
                width="fit-content"
                size="md"
                bgcolor={africredColors.neutrals.white[10]}
            >
                <Text type="p" weight="medium">
                    Back
                </Text>
            </Button>
            <Button
                onClick={() => goToNext({ id: 1 })}
                width="fit-content"
                size="md"
                bgcolor={
                    lastStep
                        ? brandTheme.brand.secondary
                        : africredColors.primary.green[600]
                }
            >
                {loading ? <Spinner />:
                <Text type="p" weight="bold" color={lastStep ? "white": "black"}>
                    {lastStep ? 'Send Money' : 'Continue'}
                </Text>
                }
            </Button>
        </div>
    )
}

function StepOne({ goToNext, data, setData }) {
    const auth = useAuth()
    const [isPhoneValid, setIsPhoneValid] = useState(false)

    return (
        <>
            <FormSection className="flex flex-col items-between w-full sm:w-2/3 lg:w-2/4 2xl:w-2/5 gap-4 rounded">
                <FormInputs>
                    <FormRow>
                        <div>
                            <Text
                                textalign="left"
                                type="h4"
                                weight="medium"
                                margin="0 0 0.5rem 0"
                            >
                                Student or client details
                            </Text>
                        </div>
                    </FormRow>

                    <FormRow>
                        <div>
                            <Text
                                textalign="left"
                                type="h5"
                                weight="medium"
                                margin="0 0 1rem 0"
                            >
                                First Name
                            </Text>
                            <Input
                                icon={<Icon type="person" />}
                                type="text"
                                size="md"
                                placeholder="First Name"
                                autoComplete="off"
                                value={data.firstName}
                                onChange={e =>
                                    setData(prev => ({
                                        ...prev,
                                        firstName: e.target.value,
                                    }))
                                }
                            />
                        </div>
                        <div>
                            <Text
                                textalign="left"
                                type="h5"
                                weight="medium"
                                margin="0 0 1rem 0"
                            >
                                Last Name
                            </Text>
                            <Input
                                icon={<Icon type="person" />}
                                type="text"
                                size="md"
                                placeholder="Last Name"
                                autoComplete="off"
                                value={data.lastName}
                                onChange={e =>
                                    setData(prev => ({
                                        ...prev,
                                        lastName: e.target.value,
                                    }))
                                }
                            />
                        </div>
                    </FormRow>

                    <FormRow>
                        <div>
                            <Text
                                textalign="left"
                                type="h5"
                                weight="medium"
                                margin="0 0 1rem 0"
                            >
                                Email
                            </Text>
                            <Input
                                icon={<Icon type="email" />}
                                type="email"
                                size="md"
                                placeholder="Email"
                                autoComplete="off"
                                value={data.email}
                                onChange={e =>
                                    setData(prev => ({
                                        ...prev,
                                        email: e.target.value,
                                    }))
                                }
                            />
                        </div>
                        <div>
                            <Text
                                textalign="left"
                                type="h5"
                                weight="medium"
                                margin="0 0 1rem 0"
                            >
                                Phone Number
                            </Text>
                            <Input
                                size="md"
                                type="phone"
                                value={data.phoneNumber}
                                onChange={(val, country) => {
                                    setData(prev => ({
                                        ...prev,
                                        phoneNumber: val,
                                        countryCode: country.countryCode,
                                    }))
                                }}
                                setIsPhoneValid={setIsPhoneValid}
                            />
                        </div>
                    </FormRow>

                    <FormRow>
                        <div className="w-full">
                            <Text
                                textalign="left"
                                type="h5"
                                weight="medium"
                                margin="0 0 1rem 0"
                            >
                                City of residence
                            </Text>
                            <Input
                                icon={<Icon type="location" />}
                                type="text"
                                size="md"
                                placeholder="City"
                                autoComplete="off"
                                value={data.cityOfResidence}
                                onChange={e =>
                                    setData(prev => ({
                                        ...prev,
                                        cityOfResidence: e.target.value,
                                    }))
                                }
                            />
                        </div>
                        <div className="w-full">
                            <Text
                                textalign="left"
                                type="h5"
                                weight="medium"
                                margin="0 0 1rem 0"
                            >
                                Country of Residence
                            </Text>

                            <AntdSelect
                                showSearch
                                options={formatSelectOptionForAntd(
                                    auth.enumValues.countryEnum,
                                )}
                                // defaultValue="nigeria"
                                value={data.countryOfStudy}
                                setValue={val => {
                                    setData(prev => ({
                                        ...prev,
                                        countryOfStudy: val,
                                    }))
                                }}
                            />
                        </div>
                    </FormRow>
                </FormInputs>
            </FormSection>

            <StepFooter
                goBack={() => {}}
                goToNext={() => {
                    if (!isPhoneValid) {
                        toast.error('Please enter a valid phone number!')
                    } else if (
                        data.email &&
                        data.firstName &&
                        data.lastName &&
                        data.phoneNumber &&
                        data.cityOfResidence &&
                        data.countryOfStudy
                    ) {
                        goToNext()
                    } else {
                        toast.error('Please provide all details...')
                    }
                }}
            />
        </>
    )
}
function StepTwo({ goToNext, data, goBack, setData }) {
    const auth = useAuth()
    const axiosPrivate = useAxiosPrivate()
    const [selectedMenuOption, setSelectedMenuOption] = useState({
        icon: 'https://afri-cred.s3.eu-west-2.amazonaws.com/flags/Richoco+Studio++Samples/US+-+United+States.png',
        value: 'USD',
    })

    useEffect(() => {
        const calculateFee = async ({
            amount,
            transactionType,
            currency,
            setData,
            currencyToPay,
        }) => {
            try {
                const response = await axiosPrivate.get(
                    `/app-configuration/processing-fee/${
                        amount || 0
                    }/${currency}/${transactionType}/${currencyToPay}`,
                )

                if (response.data) {
                    setData(prev => ({
                        ...prev,
                        amountToRemitInCurrency:
                            response?.data?.remittanceInCurrencyToPay,
                        amountInNaira: response?.data?.remittanceInCurrency,
                        amountInDollars: response?.data?.amountInCurrency,
                        remittanceProcessingFeeInCurrency:
                            response?.data?.processingFeeInCurrency,
                        remittanceProcessingFee:
                            response?.data?.remittanceProcessingFee,
                        processingFeeInNaira:
                            response?.data?.processingFeeInCurrency,
                        currency: response?.data?.currency,
                        currencyToPay: response?.data?.currencyToPay,
                    }))
                }
            } catch (e) {
                if (
                    e?.response?.data?.statusCode !== 500 &&
                    e?.response?.data?.statusCode !== 404
                ) {
                    toast.error(e?.response?.data?.message)
                }
            }
        }
        // calculateFee(data.amountToRemit, selectedMenuOption.value);
        calculateFee({
            amount: data.amountToRemit,
            transactionType: 'Remittance',
            currency: selectedMenuOption.value,
            setData,
            currencyToPay: data?.currencyToPay,
        })
    }, [
        axiosPrivate,
        data?.amountToRemit,
        selectedMenuOption?.value,
        setData,
        data?.currencyToPay,
    ])

    return (
        <>
            <FormSection className="flex flex-col items-between w-full sm:w-2/3 lg:w-2/4 2xl:w-2/5 gap-4 rounded">
                <FormRow>
                    <div>
                        <Text
                            textalign="left"
                            type="h5"
                            weight="medium"
                            margin="0 0 1rem 0"
                        >
                            From Currency
                        </Text>
                        <CountryDropdown
                            currencyToPay={selectedMenuOption.value}
                            setData={({value, icon}) => setSelectedMenuOption({icon, value})}
                            currencyToPayIcon={selectedMenuOption.icon}
                        />
                    </div>
                    <div>
                        <Text
                            textalign="left"
                            type="h5"
                            weight="medium"
                            margin="0 0 1rem 0"
                        >
                            To Currency
                        </Text>
                        <CountryDropdown
                            currencyToPay={data?.currencyToPay}
                            setData={({value, icon}) => setData(prev => ({
                                ...prev,
                                currencyToPay: value,
                                currencyToPayIcon: icon,
                            }))}
                            currencyToPayIcon={data?.currencyToPayIcon}
                        />
                    </div>
                </FormRow>
                <FormRow>
                    <div style={{ flex: 1 }}>
                        <Text textalign="left" type="h5" weight="medium" style={{ marginBottom: "1rem" }}>
                            Amount in {selectedMenuOption.value}
                        </Text>
                        <Input
                            type="number"
                            size="md"
                            padding="14px"
                            placeholder="Amount"
                            autoComplete="off"
                            value={data?.amountToRemit}
                            onChange={(e) => {
                                if (e.target.value >= 0) {
                                    setData(prev => ({
                                        ...prev,
                                        amountToRemit: e.target.value,
                                    }))
                                }
                            }}
                        />
                    </div>
                </FormRow>
                <Wrapper>
                    <div className='flex gap-2 min-w-[90px] mr-3'>
                        <img
                            src={data?.currencyToPayIcon}
                            alt="flag"
                        />
                        <Text className="w-fit" type="p">
                            {data?.currencyToPay}
                        </Text>
                    </div>
                    <div className="flex flex-col items-end">
                        <Text type="small" textalign="right" color={africredColors.neutrals.night[600]}>
                            You will be paying
                        </Text>
                        <Text type="small" textalign="right">
                            {isNaN(
                            data.amountToRemitInCurrency
                        )
                            ? 0
                            : data.amountToRemitInCurrency|| 0}
                        </Text>
                    </div>
                </Wrapper>
                {/* <div className="w-full">
                    <Text textalign="left" type="h5" weight="medium">
                        Amount
                    </Text>
                </div>

                <InputWithAddonSelect
                    inputType="number"
                    background={africredColors.neutrals.night[80]}
                    inputValue={data?.amountToRemit}
                    inputLabel="Actual Amount"
                    currency={selectedMenuOption.value}
                    setInputValue={e => {
                        setData(prev => ({
                            ...prev,
                            amountToRemit: e.target.value,
                        }))
                    }}
                    placeholder="Amount"
                    selectMenuData={auth.enumValues.transactionFlags}
                    selectedMenuOption={selectedMenuOption}
                    setSelectedMenuOption={setSelectedMenuOption}
                    isRemittance={true}
                    currencyToPay={data?.currencyToPay}
                    amountToRemitInCurrency={
                        isNaN(data?.amountToRemitInCurrency)
                            ? 0
                            : data?.amountToRemitInCurrency
                    }
                />

                <PricingConversionBreakdown
                    percentageProcessingFeeText="Processing fee (Flat rate)"
                    currencyDetails={selectedMenuOption}
                    feeInCurrencyToPay={formatNumber(
                        data?.remittanceProcessingFeeInCurrency || 0,
                    )}
                    selectMenuData={auth.enumValues.transactionFlags}
                    currencyToPay={data?.currencyToPay}
                    setData={setData}
                    currencyToPayIcon={data?.currencyToPayIcon}
                    processingFeeInChoosenCurrency={formatNumber(
                        data.remittanceProcessingFee,
                    )}
                    totalAmount={
                        isNaN(
                            data.amountToRemitInCurrency +
                                data.remittanceProcessingFeeInCurrency,
                        )
                            ? 0
                            : data.amountToRemitInCurrency +
                              data.remittanceProcessingFeeInCurrency
                    }
                    isRemittance={true}
                /> */}

                <div className="w-full mt-2">
                    <Text textalign="left" type="h5" weight="medium">
                        Admission status
                    </Text>
                </div>

                <SelectInput
                    options={auth.enumValues.admissionStatusEnum}
                    value={data.admissionStatus}
                    setValue={val => {
                        setData(prev => ({ ...prev, admissionStatus: val }))
                    }}
                />

                <div className="w-full">
                    <Text
                        textalign="left"
                        type="h5"
                        weight="medium"
                        margin="0 0 1rem 0"
                    >
                        Purpose (e.g Student Tuition)
                    </Text>

                    <Input
                        type="textArea"
                        borderRadius="1rem"
                        value={data.purpose}
                        onChange={e => {
                            setData(prev => ({
                                ...prev,
                                purpose: e.target.value,
                            }))
                        }}
                    />
                </div>

                {/* <div className="w-full mt-2">
          <Text textalign="left" type="h5" weight="medium" margin="0 0 1rem 0">
            Purpose
          </Text>
        </div>

        <AntdSelect
          options={formatSelectOptionForAntd(
            auth?.enumValues?.purposeEnum
          )}
          value={data.purpose}
          setValue={(val) => {
            setData((prev) => ({ ...prev, purpose: val }));
          }}
        /> */}
            </FormSection>

            <StepFooter
                goBack={goBack}
                goToNext={() => {
                    if (
                        data.amountToRemit &&
                        data.amountToRemitInCurrency &&
                        // data.remittanceProcessingFeeInCurrency &&
                        data.purpose
                    ) {
                        goToNext(data)
                    } else {
                        toast.error(
                            'Please provide all details, if all field provided reload and try again!!...',
                        )
                    }
                }}
                className={'w-full sm:w-2/3 lg:w-2/4 2xl:w-2/5'}
            />
        </>
    )
}
function StepThree({ goToNext, data, goBack, setData }) {
    const auth = useAuth()
    return (
        <>
            <FormSection className="flex flex-col items-between w-full sm:w-2/3 lg:w-2/4 2xl:w-2/5 gap-4 rounded">
                <div className="w-full">
                    <Text textalign="left" type="h5" weight="medium">
                        School offer letter (Optional)
                    </Text>
                    <Text textalign="left" type="p" margin="1rem 0 0 0">
                        Offer letter should contain school account details and
                        other beneficiaries
                    </Text>
                </div>
                <DragAndDropFiles
                    supportedFormats="JPEG, PNG & PDF"
                    file={data?.schoolOfferLetter}
                    setFile={value =>
                        setData(prev => {
                            return {
                                ...prev,
                                schoolOfferLetter: prev?.schoolOfferLetter
                                    ? Object.assign(
                                          prev?.schoolOfferLetter,
                                          value,
                                      )
                                    : value,
                            }
                        })
                    }
                />
                `{' '}
                <div className="w-full">
                    <Text
                        textalign="left"
                        margin="0 0 1rem 0"
                        type="h5"
                        weight="medium"
                    >
                        Proof of Identity (Optional)
                    </Text>
                    {/* proofOfIdentityEnum */}
                    <AntdSelect
                        options={formatSelectOptionForAntd(
                            auth.enumValues.proofOfIdentityEnum,
                        )}
                        value={data?.proofOfIdentity?.typeOfProofOfIdentity}
                        setValue={val => {
                            setData(prev => ({
                                ...prev,
                                proofOfIdentity: {
                                    ...prev.proofOfIdentity,
                                    typeOfProofOfIdentity: val,
                                },
                            }))
                        }}
                    />
                </div>
                <DragAndDropFiles
                    supportedFormats="JPEG, PNG & PDF"
                    file={data.proofOfIdentity}
                    setFile={value =>
                        setData(prev => ({
                            ...prev,
                            proofOfIdentity: prev?.proofOfIdentity
                                ? Object.assign(prev?.proofOfIdentity, value)
                                : value,
                        }))
                    }
                />
            </FormSection>
            <StepFooter
                goToNext={() => {
                    if (data?.proofOfIdentity?.id && !data?.proofOfIdentity.typeOfProofOfIdentity) {
                        toast.error("Please provide file type for proof of identity...");
                    } else {
                        goToNext(data);
                    }
                }}
                goBack={goBack}
            />
        </>
    )
}
// function StepFour({ goToNext, data, goBack, setData }) {
//   return (
//     <>
//       <FormSection className="flex flex-col items-between w-full sm:w-2/3 lg:w-2/4 2xl:w-2/5 gap-4 rounded">
//         <div className="w-full">
//           <Text textalign="left" type="h5" weight="medium">
//             International passport (Optional)
//           </Text>
//         </div>

//         <DragAndDropFiles
//           supportedFormats="JPEG, PNG & PDF"
//           file={data.proofOfIdentity}
//           setFile={(value) =>
//             setData((prev) => ({
//               ...prev,
//               proofOfIdentity: prev.proofOfIdentity
//                 ? Object.assign(prev.proofOfIdentity, value)
//                 : value,
//             }))
//           }
//         />
//       </FormSection>
//       <StepFooter
//         goToNext={() => {
//           if (data.proofOfIdentity?.name) {
//             goToNext(data);
//           } else {
//             toast.error("Please provide a file...");
//           }
//         }}
//         goBack={goBack}
//       />
//     </>
//   );
// }
function StepFive({ data, onFinish, goBack, setData }) {
    return (
        <>
            <FormSection className="flex flex-col items-between w-full sm:w-2/3 lg:w-3/6 gap-4 rounded">
                <Grid>
                    {[
                        {
                            title: 'School offer letter',
                            subTitle: data?.schoolOfferLetter?.name,
                        },
                        {
                            title: 'International passport',
                            subTitle: data?.proofOfIdentity?.name,
                        },
                        { title: 'Purpose', subTitle: data?.purpose },
                        {
                            title: `${data.currencyToPay} ${formatNumber(
                                data?.amountToRemitInCurrency,
                            )}`,
                            subTitle: `You will be paying`,
                        },
                    ].map(item => (
                        <GridItem key={item.title}>
                            <Icon
                                type="checked-circle"
                                width={25}
                                height={25}
                                stroke={africredColors.primary.green[600]}
                            />
                            <div className="flex flex-col gap-2">
                                <Text type="h5" weight="medium">
                                    {item?.title}
                                </Text>
                                <Text
                                    type="p"
                                    color={africredColors.neutrals.night[500]}
                                >
                                    {item?.subTitle}
                                </Text>
                            </div>
                        </GridItem>
                    ))}
                </Grid>

                <div className="w-full">
                    <Text
                        margin="1rem 0 0 0"
                        textalign="left"
                        type="h4"
                        weight="medium"
                        color="black"
                    >
                        What next?
                    </Text>

                    <Text textalign="left" type="p" margin="1rem 0 0 0">
                        Kindly
                        proceed to make payment if you are sure of the details
                        provided and expect a provisional Issue of receipt of
                        local currency payment.
                    </Text>
                </div>

                <div className="flex flex-wrap items-center justify-center w-full gap-4">
                    {[
                        access,
                        fcmb,
                        // future_finance,
                        gtb,
                        lendwise,
                        polaris,
                        power,
                        // prodigy,
                        stilt,
                    ].map((item, idx) => (
                        <img
                            key={idx}
                            src={item}
                            alt={`partner-${idx}`}
                            className="w-14 h-8"
                        />
                    ))}
                </div>
            </FormSection>
            <StepFooter
                lastStep
                loading={data.loading}
                goToNext={() => onFinish(data)}
                goBack={goBack}
                className={'sm:w-2/3 lg:w-3/6'}
            />
        </>
    )
}
