import { brandTheme } from "../../../theme";
import { Button, Icon, Input, PasswordInput, Spinner, Text } from "../../atoms";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { AuthTemplate } from "../../templates/AuthTemplate";
import { useContext, useState } from "react";
import axios from "../../../api/axios";
import AuthContext from "../../../store/contexts/AuthProvider";
import { africredColors } from "../../../theme/colors";
import useAuth from "../../../hooks/useAuth";
import { toast } from "react-toastify";

export const AgentLogin = () => {
  const auth = useAuth();
  const { setAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/dashboard";
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  // create state to manage input values.

  const handleSubmit = async () => {
    if (loading) return;

    try {
      setLoading(true)
      const response = await axios.post("/account/login", {
        email,
        password,
      });

      // Set the auth data here e.g., setAuth({ user, pwd, roles, accessToken });
      if (response?.data) {
        setLoading(false)
        setAuth(response?.data);

        if (!auth.kycStatus && auth.accountType !== "Agent") {
          navigate("/onboard-student", { replace: true });
        } else {
          // navigate to the route from whence the user came or home.
          navigate(from || "/dashboard", { replace: true });
        }
      }
    } catch (err) {
      setLoading(false)
      if (err?.response?.status === 401) {
        setError("Invalid credentials. Check email/password!");
      } else if (err?.response?.status === 458) {
        setError("Account suspended!");
      } else {
        if(err?.response?.data?.message){
          setError(err?.response?.data?.message);
        }else{
          toast.error("An Error Occur while making request, check your internet connection and try again")
        }
      }
    }
  };

  return (
    <AuthTemplate title="Institute Login" subtitle="Login to access your dashboard">
      {error ? (
        <Text type="p" color={africredColors.secondary.red[600]}>
          {error}
        </Text>
      ) : null}
      <Input
        icon={<Icon type="email" />}
        type="text"
        size="md"
        placeholder="Email"
        autoComplete="off"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <PasswordInput
        size="md"
        placeholder="Password"
        autoComplete="off"
        padding="12px"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <div className="w-full">
        <Button
          onClick={handleSubmit}
          width="100%"
          size="md"
          bgcolor={brandTheme.brand.secondary}
          style={{color:"#fff"}}
        >
          {loading? <Spinner />: 'Login'}
        </Button>
      </div>

      <div className="flex justify-between w-full">
        <Link to="/signup">
          <Text type="small">Don’t have an account? Sign up</Text>
        </Link>
        <Link to="/forgot-password">
          <Text type="small">Forgot password</Text>
        </Link>
      </div>
    </AuthTemplate>
  );
};
