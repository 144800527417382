import { useLocation, Navigate, useNavigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import { useEffect } from 'react';

const RequireAuth = ({ allowedRoles, path, children }) => {
    const auth = useAuth()
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        if (auth?.email && !auth.kycStatus) {
            navigate('/onboard-student', { replace: true })
        }
    }, [auth])

    return auth?.email ? (
        <>{children}</>
    ) : (
        <Navigate to={path || '/login'} state={{ from: location }} replace />
    )
}

export default RequireAuth
