import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Text } from "../../atoms/Text";
import { africredColors } from "../../../theme/colors";
import { sideNavLinks } from "./sideNavLinks";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import { brandTheme, screen } from "../../../theme";
import { NavBar } from "../../molecules/NavBar";
import { MobileSideNav } from "../../molecules/Modal/SideNav";
import useAuth from "../../../hooks/useAuth";

const Wrapper = styled.div`
  height: 100vh;
  background-color: ${brandTheme.brand.backgroundSkin};
`;

const ContentArea = styled.section`
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-top: 60px;
  background-color: inherit;
  overflow: hidden;

  @media only screen and (${screen.md}) {
    flex-direction: row;
    padding-top: 97px;
  }
`;

const sideNavCSS = css`
  height: calc(100vh - 94px);
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  width: 300px;
  padding: 32px 20px 20px 20px;
  background-color: inherit;

  div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  a:hover,
  .active {
    border-radius: 24px;
    background-color: ${africredColors.neutrals.night[100]};

    p {
      font-weight: bold;
    }
  }

  @media only screen and (${screen.md}) {
    display: flex;
  }
`;

const SideNav = styled.aside`
  display: none;
  ${sideNavCSS};
`;

export const MobileSideNavWrapper = styled.aside`
  ${sideNavCSS};
  width: 100%;
  padding: 32px 0;
  height: 87vh;
`;

const MainContent = styled.section`
  height: 100%;
  overflow-y: auto;
  width: 100%;
  padding: 32px 12px 12px 12px;

  @media only screen and (${screen.md}) {
    padding: 32px 20px 20px 20px;
  }
`;

export const SideNavItems = styled(NavLink)`
  display: flex;
  padding: 16px 24px;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const DashboardTemplate = () => {
  const auth = useAuth();
  const settingsNavLinks = sideNavLinks.settingLinks.map((link) => {
    if (auth.accountType === "Agent" && link.name !== "KYC Details") {
      return link;
    }
    return link;
  });
  const location = useLocation();
  const isSettingsPage = location.pathname.split("/").includes("settings");
  const topLink = isSettingsPage ? settingsNavLinks : sideNavLinks.top;
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);

  useEffect(() => {
    setIsSideNavOpen(false);
  }, [location]);

  return (
    <Wrapper>
      <NavBar setSideNav={setIsSideNavOpen} />

      <ContentArea>
        <SideNav>
          <div>
            {isSettingsPage && <Text type="small">SETTINGS</Text>}
            {topLink.map((linkItem) => {
              if((auth.accountType === "Student/Guardian") && (linkItem.isStudent===false)) return null;
              if((auth.accountType === "Agent") && (linkItem.isAgent===false)) return null;
              return (
                <SideNavItems to={linkItem.link} key={linkItem.name}>
                  <div>{linkItem.icon}</div>
                  <Text type="p">{linkItem.name}</Text>
                </SideNavItems>
              )
            })}
          </div>

          {!isSettingsPage && (
            <div>
              {sideNavLinks.bottom.map((linkItem) => (
                <SideNavItems to={linkItem.link} key={linkItem.name}>
                  <div>{linkItem.icon}</div>
                  <Text type="p">{linkItem.name}</Text>
                </SideNavItems>
              ))}
            </div>
          )}
        </SideNav>
        <MainContent>
          <Outlet />
        </MainContent>
      </ContentArea>
      {isSideNavOpen && (
        <MobileSideNav isOpen={isSideNavOpen} setIsOpen={setIsSideNavOpen}>
          <MobileSideNavWrapper>
            <div>
              {isSettingsPage && <Text type="small">SETTINGS</Text>}
              {topLink.map((linkItem) => {
                if((auth.accountType === "Student/Guardian") && (linkItem.isStudent===false)) return null;
                if((auth.accountType === "Agent") && (linkItem.isAgent===false)) return null;
                return (
                  <SideNavItems to={linkItem.link} key={linkItem.name}>
                      <div>{linkItem.icon}</div>
                      <Text type="p">{linkItem.name}</Text>
                    </SideNavItems>
                  )
              })}
            </div>

            {!isSettingsPage && (
              <div>
                {sideNavLinks.bottom.map((linkItem) => (
                  <SideNavItems to={linkItem.link} key={linkItem.name}>
                    <div>{linkItem.icon}</div>
                    <Text type="p">{linkItem.name}</Text>
                  </SideNavItems>
                ))}
              </div>
            )}
          </MobileSideNavWrapper>
        </MobileSideNav>
      )}
    </Wrapper>
  );
};
