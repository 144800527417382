import React from 'react'
import bgImage from '../../../assets/pngs/hero.png'
import polarisImage from '../../../assets/pngs/partners/polaris.png'
import fcmbImage from '../../../assets/pngs/partners/fcmb.png'
import lendwiseImage from '../../../assets/pngs/partners/lendwise.png'
import accessImage from '../../../assets/pngs/partners/access.png'
import gtbImage from '../../../assets/pngs/partners/gtb.png'
import stiltImage from '../../../assets/pngs/partners/stilt.png'
import homeVectorLeft from '../../../assets/svgs/home-vector-left.svg'
import homeVectorRight from '../../../assets/svgs/home-vector-right.svg'
import { Button, Icon, Text } from '../../atoms'
import { africredColors } from '../../../theme/colors'
import { Link } from 'react-router-dom'
import { AppNav } from '../../molecules/AppNav'

export const SmeHome = () => {
    return (
        <div className="w-full flex">
            <div className="w-full flex flex-col gap-10">
                <AppNav />

                <div className="w-full flex flex-col gap-8 p-4 lg:px-28 mt-8 md:mt-0">
                    <div className="w-full flex items-center justify-center">
                        <div className="ml-[-110px] hidden lg:block">
                            <img src={homeVectorLeft} alt="vector" />
                        </div>
                        <div className="w-full flex flex-col gap-1 items-center justify-center text-center">
                            <Text className="" type="p" weight="medium">
                                Access Personal & Business Loan From
                            </Text>
                            <Text
                                className="-mt-1"
                                type="h1"
                                weight="medium"
                                fontSize="3.5rem"
                            >
                                Africa&apos;s Largest Credit Marketplace
                            </Text>
                            <Text
                                className="mt-3 max-w-[636px]"
                                type="p"
                                weight="regular"
                                color="#5d5d5d"
                                hovercolor="#5d5d5d"
                            >
                                Whether you're looking to fund your
                                entrepreneurial ventures or seeking financial
                                support for personal endeavors, ProU offers
                                tailored solutions to meet your needs.
                            </Text>
                            <div className="w-full max-w-[430px] flex flex-col sm:flex-row gap-2 sm:border-[1px] border-[#c7d4fe] rounded-full mt-3 p-2">
                                <Link
                                    to={{
                                        pathname: '/sme-loans/apply',
                                        search: '?type=personal',
                                    }}
                                    className="w-full md:w-1/2"
                                >
                                    <Button
                                        className={`w-full h-12 rounded-full bg-white hover:bg-[${africredColors.primary.blue[600]}] text-[${africredColors.primary.blue[600]}] hover:text-white text-sm`}
                                        border={`1px solid ${africredColors.primary.blue[600]}`}
                                    >
                                        <Text
                                            type="p"
                                            weight="regular"
                                            textWrap="nowrap"
                                            fontSize="1rem"
                                        >
                                            Personal Loan
                                        </Text>
                                    </Button>
                                </Link>
                                <Link
                                    to={{
                                        pathname: '/sme-loans/apply',
                                        search: '?type=business',
                                    }}
                                    className="w-full md:w-1/2"
                                >
                                    <Button
                                        className={`w-full h-12 rounded-full bg-[${africredColors.primary.blue[600]}] hover:bg-white text-white hover:text-[${africredColors.primary.blue[600]}]`}
                                        border={`1px solid ${africredColors.primary.blue[600]}`}
                                    >
                                        <Text
                                            type="p"
                                            weight="regular"
                                            textWrap="nowrap"
                                            fontSize="1rem"
                                        >
                                            Business Loan
                                        </Text>
                                        <Icon type="arrow-right" />
                                    </Button>
                                </Link>
                            </div>
                        </div>
                        <div className="mr-[-110px] hidden lg:block">
                            <img src={homeVectorRight} alt="vector" />
                        </div>
                    </div>

                    <div className="w-full flex flex-col-reverse md:flex-row bg-[#eef3ff] rounded-2xl md:px-12 gap-8 mt-8 md:mt-20">
                        <div className="md:mt-[-50px] h-full w-full  md:w-[400px] md:min-w-[400px]">
                            <img
                                src={bgImage}
                                alt="hero"
                                className="w-full h-full md:h-[calc(100%+50px)]"
                            />
                        </div>
                        <div className="flex flex-col gap-4 py-8 px-5 md:px-0">
                            <div className="flex items-center w-full gap-4 border-b-[1px] border-b-[#c7d4fe] py-6">
                                <div
                                    className={`text-[${africredColors.primary.blue[600]}]`}
                                >
                                    <Icon type="checked-bullet" />
                                </div>
                                <div className="flex flex-col gap-1">
                                    <Text
                                        type="h5"
                                        weight="medium"
                                        color="#151515"
                                        fontSize="1.3rem"
                                    >
                                        Simple Application Process
                                    </Text>
                                    <Text
                                        type="small"
                                        weight="regular"
                                        color="#5d5d5d"
                                        hovercolor="#5d5d5d"
                                        fontSize="1rem"
                                    >
                                        Say goodbye to lengthy forms and complex
                                        procedures
                                    </Text>
                                </div>
                            </div>
                            <div className="flex items-center w-full gap-4 border-b-[1px] border-b-[#c7d4fe] py-6">
                                <div
                                    className={`text-[${africredColors.primary.blue[600]}]`}
                                >
                                    <Icon type="checked-bullet" />
                                </div>
                                <div className="flex flex-col gap-1">
                                    <Text
                                        type="h5"
                                        weight="medium"
                                        color="#151515"
                                        fontSize="1.3rem"
                                    >
                                        Tailored Financial Solutions
                                    </Text>
                                    <Text
                                        type="small"
                                        weight="regular"
                                        color="#5d5d5d"
                                        hovercolor="#5d5d5d"
                                        fontSize="1rem"
                                    >
                                        Whether you&apos;re an individual or a
                                        business, we offer personalized
                                        financial strategies to help you achieve
                                        your goals.
                                    </Text>
                                </div>
                            </div>
                            <div className="flex items-center w-full gap-4 border-b-[1px] border-b-transparent py-6">
                                <div
                                    className={`text-[${africredColors.primary.blue[600]}]`}
                                >
                                    <Icon type="checked-bullet" />
                                </div>
                                <div className="flex flex-col gap-1">
                                    <Text
                                        type="h5"
                                        weight="medium"
                                        color="#151515"
                                        fontSize="1.3rem"
                                    >
                                        Money in Your Bank Quickly
                                    </Text>
                                    <Text
                                        type="small"
                                        weight="regular"
                                        color="#5d5d5d"
                                        hovercolor="#5d5d5d"
                                        fontSize="1rem"
                                    >
                                        Enjoy the convenience of rapid deposits
                                        directly into your bank account
                                    </Text>
                                    <div className="w-full flex flex-wrap gap-2 mt-2">
                                        <img
                                            src={polarisImage}
                                            alt="partner"
                                            className="w-14 h-8"
                                        />
                                        <img
                                            src={fcmbImage}
                                            alt="partner"
                                            className="w-14 h-8"
                                        />
                                        <img
                                            src={lendwiseImage}
                                            alt="partner"
                                            className="w-14 h-8"
                                        />
                                        <img
                                            src={accessImage}
                                            alt="partner"
                                            className="w-14 h-8"
                                        />
                                        <img
                                            src={gtbImage}
                                            alt="partner"
                                            className="w-14 h-8"
                                        />
                                        <img
                                            src={stiltImage}
                                            alt="partner"
                                            className="w-14 h-8"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
