import useAuth from "./useAuth"

const useStudentDefaultData = ()=>{

    const auth = useAuth();
    const isStudent = auth?.accountType === "Student/Guardian";
    // const userCountry = auth?.address?.country;
    // const countryCode = getCountryCode(getCountryCode(userCountry));
    const userPhoneNumber = auth?.phoneNumber?.code.slice(1) + auth?.phoneNumber?.number;
    
    const data = {
        email: isStudent? auth.email: "",
        firstName: isStudent? auth.firstName:"",
        lastName: isStudent? auth.lastName: "",
        phoneNumber:!isStudent? userPhoneNumber: "", 

    }
    return {
        data
    };
}

export default useStudentDefaultData;