import styled from "styled-components";
import { CloseablePageTemplage } from "../../templates";
import { brandTheme, screen } from "../../../theme";
import { AntdSelect, Button, Icon, Input, Spinner, Text } from "../../atoms";
import { africredColors } from "../../../theme/colors";
import { OnboardingFlow, StepProgressIndicator } from "../../molecules";
import { useEffect, useMemo, useState } from "react";
import useAuth from "../../../hooks/useAuth";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import {
  formatNumber,
  formatSelectOptionForAntd,
  getCountryCode,
  separatePhoneNumberString,
} from "../../../utils";
import { PricingConversionBreakdown } from "../../molecules/PricingConversionBreakdown";
import { useNavigate, useSearchParams } from "react-router-dom";
import { defaultCurrency } from "../../../constants";

const FormSection = styled.section`
  background-color: ${brandTheme.brand.backgroundSkin4};
  border-radius: 16px;
  padding: 48px 24px;
  height: fit-content;

  @media only screen and (${screen.md}) {
    padding: 48px;
  }
`;
const TitleWrapper = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 32px;
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;

  @media (min-width: 840px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
const GridItem = styled.div`
  display: flex;
  padding: 16px;
  gap: 10px;
  border-radius: 16px;
  background: ${africredColors.primary.green[50]};
`;
const StyledRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  padding: 18px;
  gap: 16px;
  background: ${africredColors.primary.green[50]};

  @media only screen and (${screen.md}) {
    flex-direction: row;
  }
`;
const FormInputs = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
`;
const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  > div {
    width: 100%;
  }

  @media only screen and (${screen.md}) {
    flex-direction: row;
  }
`;

export const AdmissionForm = ({ children }) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const isStudent = auth?.accountType == "Student/Guardian";
  const userCountry = auth?.address?.country;
  const userCountryCode = getCountryCode(userCountry)
  const userPhoneNumber = auth?.phoneNumber?.code.slice(1) + auth?.phoneNumber?.number;
  const [params] = useSearchParams();
  const stringedUni = params.get('university');
  
  const university = useMemo(() => {
    try {
      return JSON.parse(stringedUni)
    } catch {
      return {}
    }
  }, [stringedUni])

  const [onboardingData, setOnboardingData] = useState({
    loading: false,
    email: isStudent ? auth.email : "",
    firstName: isStudent ? auth.firstName : "",
    lastName: isStudent ? auth.lastName : "",
    phoneNumber: isStudent ? userPhoneNumber: "",
    countryCode: isStudent ? userCountryCode: "",
    cityOfResidence: isStudent ? auth?.address?.city : "",
    countryOfResidence: isStudent ? auth?.address?.country : "", 
    schoolName: university?.schoolName || "",
    state: university?.state?.name || university?.state || "",
    country: university?.country?.name || "",
    university: university?.id || "",
    regionOfSchool: "",
    typeOfAdmission: "",
    courseOfStudy: "",
    workExperience: "0 - 2 years",
    profession: "Employed",
    // processingFeeInNaira: 50000,
    processingFee:200,
    processingFeeInCurrency:"",
    paymentStatus: "pending",
    currencyToPay: defaultCurrency.value,
    currencyToPayIcon: defaultCurrency.icon,
    currency: "USD",
  });
  const [currentIndex, setCurrentIndex] = useState(0);
  const [stepCount, setStepCount] = useState(null);
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    if (!university?.id) {
      navigate(-1);
    }
  }, [university])

  const onNext = (stepData, numberOfSteps) => {
    setOnboardingData({ ...onboardingData, ...stepData });
    if (currentIndex < numberOfSteps) setCurrentIndex(currentIndex + 1);
  };

  const onFinish = async (data) => {
    if (data.loading) return

    setOnboardingData(prev => ({
        ...prev,
        loading: true,
    }))

    const payload = {
      ...data,
      regionOfSchool: `${data?.state || ""}, ${data?.country || ""}`,
      phoneNumber: separatePhoneNumberString(
        data?.phoneNumber,
        data.countryCode
      ),
    };

    if (isStudent) {
      payload.phoneNumber = auth?.phoneNumber;
    }
    
    try {
      const prepareAmount = Number((Number(data.processingFeeInCurrency) * 100).toFixed(2));

      const initPaymentRes = await axiosPrivate.post("/payment/create-checkout-session", {
        email: data.email,
        amount: prepareAmount,
        transactionType:'Admission',
        name:'Admission',
        currency:data?.currencyToPay
      });

      if (initPaymentRes.data) {
        const paymentInfo = initPaymentRes.data.paymentIntentLink;
        // TODO: visit the paystack payment website
        const response = await axiosPrivate.post("/admission/create", {
          ...payload,
          payStackReferenceNumber: paymentInfo.id,
        });

        if (response.data) {
          window.location = paymentInfo.url;
        }
      }
    } catch (e) {
      toast.error(e?.response?.data?.message);
    }

    setOnboardingData(prev => ({
      ...prev,
      loading: false,
    }))
  };

  const goBack = () => {
    if (currentIndex > 0) setCurrentIndex(currentIndex - 1);
  };

  return (
    <CloseablePageTemplage>
      <TitleWrapper>
        <Text textalign="center" weight="medium" type="h2" color="black">
          University Admission
        </Text>

        <Text textalign="center" type="p">
          We've designed our university admission with your convenience in mind.
        </Text>
      </TitleWrapper>

      <div className="flex items-center mt-4 mb-8">
        <StepProgressIndicator
          numberOfSteps={stepCount}
          currentStep={currentIndex + 1}
        />
      </div>

      <OnboardingFlow
        currentIndex={currentIndex}
        onFinish={onFinish}
        onNext={onNext}
        goBack={goBack}
        getStepCount={setStepCount}
      >
        {!isStudent && <StepOne data={onboardingData} setData={setOnboardingData} />}
        <StepTwo data={onboardingData} setData={setOnboardingData} />
        <StepThree data={onboardingData} setData={setOnboardingData} />
      </OnboardingFlow>
    </CloseablePageTemplage>
  );
};

const StepFooter = ({ goToNext, className, goBack, lastStep, loading }) => {
  return (
    <div
      className={`flex justify-between w-full sm:w-2/3 gap-4 rounded pt-8 pb-8 ${className || "lg:w-2/4 xl:w-2/5"
        }`}
    >
      <Button
        onClick={goBack}
        width="fit-content"
        size="md"
        border={`1px solid ${africredColors.neutrals.night[950]}`}
      >
        <Text type="p" weight="medium">
          Back
        </Text>
      </Button>
      <Button
        onClick={() => goToNext({ id: 1 })}
        width="fit-content"
        size="md"
        bgcolor={
          lastStep
            ? brandTheme.brand.primary
            : africredColors.primary.green[600]
        }
      >
        {loading ? <Spinner />:
        <Text type="p" weight="bold" color="black">
          {lastStep ? "Pay & Apply Now" : "Continue"}
        </Text>
        }
      </Button>
    </div>
  );
};
function StepOne({ goToNext, data, setData }) {
  const auth = useAuth();
  const navigate = useNavigate()
  const [isPhoneValid, setIsPhoneValid] = useState(false);

  return (
    <>
      <FormSection className="flex flex-col items-between w-full sm:w-2/3 lg:w-2/4 2xl:w-2/5 gap-4 rounded">
        <FormInputs>
          <FormRow>
              <div>
                  <Text
                      textalign="left"
                      type="h4"
                      weight="medium"
                      margin="0 0 0.5rem 0"
                  >
                      Student or client details
                  </Text>
              </div>
          </FormRow>
          <FormRow>
            <div>
              <Text
                textalign="left"
                type="h5"
                weight="medium"
                margin="0 0 1rem 0"
              >
                First Name
              </Text>
              <Input
                icon={<Icon type="person" />}
                type="text"
                size="md"
                placeholder="First Name"
                autoComplete="off"
                value={data.firstName}
                onChange={(e) =>
                  setData((prev) => ({ ...prev, firstName: e.target.value }))
                }
              />
            </div>
            <div>
              <Text
                textalign="left"
                type="h5"
                weight="medium"
                margin="0 0 1rem 0"
              >
                Last Name
              </Text>
              <Input
                icon={<Icon type="person" />}
                type="text"
                size="md"
                placeholder="Last Name"
                autoComplete="off"
                value={data.lastName}
                onChange={(e) =>
                  setData((prev) => ({ ...prev, lastName: e.target.value }))
                }
              />
            </div>
          </FormRow>

          <FormRow>
            <div>
              <Text
                textalign="left"
                type="h5"
                weight="medium"
                margin="0 0 1rem 0"
              >
                Email
              </Text>
              <Input
                icon={<Icon type="email" />}
                type="email"
                size="md"
                placeholder="Email"
                autoComplete="off"
                value={data.email}
                onChange={(e) =>
                  setData((prev) => ({ ...prev, email: e.target.value }))
                }
              />
            </div>
            <div>
              <Text
                textalign="left"
                type="h5"
                weight="medium"
                margin="0 0 1rem 0"
              >
                Phone Number
              </Text>
              <Input
                size="md"
                type="phone"
                value={data.phoneNumber}
                onChange={(val, country) =>
                  setData((prev) => ({
                    ...prev,
                    phoneNumber: val,
                    countryCode: country.countryCode,
                  }))
                }
                setIsPhoneValid={setIsPhoneValid}
              />
            </div>
          </FormRow>

          <FormRow>
            <div>
              <Text
                textalign="left"
                type="h5"
                weight="medium"
                margin="0 0 1rem 0"
              >
                City of residence
              </Text>
              <Input
                type="text"
                size="md"
                icon={<Icon type="location" />}
                placeholder="City"
                autoComplete="off"
                value={data.cityOfResidence}
                onChange={(e) =>
                  setData((prev) => ({
                    ...prev,
                    cityOfResidence: e.target.value,
                  }))
                }
              />
            </div>
            <div>
              <Text
                textalign="left"
                type="h5"
                weight="medium"
                margin="0 0 1rem 0"
              >
                Country of residence
              </Text>
              <AntdSelect
                showSearch
                value={data.countryOfResidence}
                options={formatSelectOptionForAntd(auth.enumValues.countryEnum)}
                setValue={(val) => {
                  setData((prev) => ({
                    ...prev,
                    countryOfResidence: val,
                  }));
                }}
              />
            </div>
          </FormRow>
        </FormInputs>
      </FormSection>

      <StepFooter
        goBack={() => navigate(-1)}
        goToNext={() => {
          if (!isPhoneValid) {
            toast.error("Please enter a valid phone number!");
          } else if (
            data.email &&
            data.firstName &&
            data.lastName &&
            data.phoneNumber &&
            data.cityOfResidence &&
            data.countryOfResidence
          ) {
            goToNext(data);
          } else {
            toast.error("Please provide all details...");
          }
        }}
        className={"w-full sm:w-2/3 lg:w-2/4 2xl:w-2/5"}
      />
    </>
  );
}
function StepTwo({ goToNext, data, goBack, setData }) {
  const auth = useAuth();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [selectedMenuOption, setSelectedMenuOption] = useState({
    icon: "https://afri-cred.s3.eu-west-2.amazonaws.com/flags/Richoco+Studio++Samples/US+-+United+States.png",
    value: "USD",
  });
  useEffect(()=>{
    const calculateFee = async ({amount, transactionType, currency, setData, currencyToPay})=>{
      try{
        const response = await axiosPrivate.get(
          `/app-configuration/processing-fee/${amount || 0}/${currency}/${transactionType}/${currencyToPay}`
        );
        if (response.data) {
          setData((prev) => ({
            ...prev,
            processingFeeInCurrency:response?.data?.remittanceInCurrencyToPay,
            currency:response?.data?.currency,
            currencyToPay:response?.data?.currencyToPay,
          }));
        }
      }catch(e){
        if (
          e?.response?.data?.statusCode !== 500 &&
          e?.response?.data?.statusCode !== 404
        ) {
          toast.error(e?.response?.data?.message);
        }
      }
    }
    calculateFee({amount: data.processingFee, transactionType:'Remittance', currency: selectedMenuOption.value, setData, currencyToPay:data?.currencyToPay})
  },[data.processingFee, setData, selectedMenuOption.value, data?.currencyToPay])

  return (
    <>
      <FormSection className="flex flex-col items-between w-full sm:w-2/3 lg:w-2/4 2xl:w-2/5 gap-4 rounded">
        <FormInputs>
          <Text
            textalign="left"
            type="h5"
            weight="medium"
          >
            Admission Fees with Loan Assistance
          </Text>
          <PricingConversionBreakdown
            currencyDetails={selectedMenuOption}
            feeInCurrencyToPay={formatNumber(data?.processingFeeInCurrency || 0)}
            selectMenuData={auth.enumValues.transactionFlags}
            currencyToPay = {data?.currencyToPay}
            setData = {setData}
            currencyToPayIcon = {data?.currencyToPayIcon}
            processingFeeInChoosenCurrency = {data?.processingFee}
            percentageProcessingFeeText = {"Admission fee"}
          />

          <StyledRow>
            <div style={{ flex: 5}}>
              <Text
                textalign="left"
                type="h5"
                weight="medium"
                margin="0 0 0.5rem 0"
              >
                {data?.schoolName}
              </Text>
              <Text
                textalign="left"
                width="fit-content"
                type="small"
                textWrap="nowrap"
              >
                {`${data?.state || ""}, ${data?.country || ""}`}
              </Text>
            </div>
            <div style={{ flex: 2}}>
              <Button
                onClick={() => navigate(-1)}
                size="sm"
                border={`1px solid ${africredColors.neutrals.night[950]}`}
              >
                <Text type="p" weight="medium">
                  Change
                </Text>
              </Button>
            </div>
          </StyledRow>

          <FormRow>
            <div>
              <Text
                textalign="left"
                type="h5"
                weight="medium"
                margin="0 0 1rem 0"
              >
                Type of admission
              </Text>
              <AntdSelect
                options={formatSelectOptionForAntd(
                  auth.enumValues.typeOfAdmission
                )}
                value={data.typeOfAdmission}
                setValue={(val) => {
                  setData((prev) => ({
                    ...prev,
                    typeOfAdmission: val,
                  }));
                }}
              />
            </div>
            <div>
              <Text
                textalign="left"
                type="h5"
                weight="medium"
                margin="0 0 1rem 0"
              >
                Choose course of study
              </Text>
              <AntdSelect
                options={formatSelectOptionForAntd(
                  auth.enumValues.courseOfStudyEnum
                )}
                value={data.courseOfStudy}
                setValue={(val) => {
                  setData((prev) => ({
                    ...prev,
                    courseOfStudy: val,
                  }));
                }}
              />
            </div>
          </FormRow>

        </FormInputs>
      </FormSection>

      <StepFooter
        goBack={goBack}
        goToNext={() => {
          if (
            data.processingFeeInCurrency &&
            data.typeOfAdmission &&
            data.courseOfStudy
          ) {
            goToNext(data);
          } else {
            toast.error("Please provide all details...");
          }
        }}
        className={"w-full sm:w-2/3 lg:w-2/4 2xl:w-2/5"}
      />
    </>
  );
}
function StepThree({ data, onFinish, goBack, setData }) {
  return (
    <>
      <FormSection className="flex flex-col items-between w-full sm:w-4/5 lg:w-4/6 gap-4 rounded">
        <Grid>
          {[
            {
              title: "Fullname",
              subTitle: `${data.firstName} ${data.lastName}`,
            },
            { title: "Email", subTitle: data.email },
            {
              title: "Phone number",
              subTitle: `${data.phoneNumber}`,
            },
            { title: "City of residence", subTitle: data.cityOfResidence },
            { title: "Country", subTitle: data.countryOfResidence },
            {
              subTitle: "Processing Fee",
              title: `${data?.currencyToPay} ${formatNumber(data.processingFeeInCurrency)}`,
            },
            { title: data.schoolName, subTitle: `${data.state}, ${data.country}` },
            { title: "Type of admission", subTitle: data.typeOfAdmission },
            { title: "Course of study", subTitle: data.courseOfStudy },
          ].map((item) => (
            <GridItem key={item.title}>
              <Icon
                type="checked-circle"
                width={25}
                height={25}
                stroke={africredColors.primary.green[600]}
              />
              <div className="flex flex-col gap-2">
                <Text type="h5" weight="medium">
                  {item.title}
                </Text>
                <Text type="p" color={africredColors.neutrals.night[500]}>
                  {item.subTitle}
                </Text>
              </div>
            </GridItem>
          ))}
        </Grid>
      </FormSection>

      <StepFooter
        lastStep
        loading={data.loading}
        goBack={goBack}
        goToNext={() => onFinish(data)}
        className={"w-full sm:w-4/5 lg:w-4/6"}
      />
    </>
  );
}