import { AntdSelect, Button, Icon, Input, Spinner, Text } from '../../atoms'
import {
    CustomCheckboxInput,
    CustomRadioInput,
    OnboardingFlow,
} from '../../molecules'
import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { brandTheme, screen } from '../../../theme'
import { StudentOnboardTemplate } from '../../templates'
import { africredColors } from '../../../theme/colors'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import AuthContext from '../../../store/contexts/AuthProvider'
import { useNavigate } from 'react-router-dom'
import useAuth from '../../../hooks/useAuth'
import {
    formatSelectOptionForAntd,
    separatePhoneNumberString,
} from '../../../utils'
import { toast } from 'react-toastify'
import { OTPComponent } from '../../organisms/OTPComponent'

const Wrapper = styled.div`
    background-color: ${brandTheme.brand.backgroundSkin};
    min-height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding-bottom: 20px;

    @media only screen and (${screen.md}) {
        padding-bottom: 120px;
    }
`
const FormInputs = styled.div`
    display: flex;
    gap: 16px;
    flex-direction: column;
`
const FormRow = styled.div`
    display: flex;
    gap: 16px;
`
const FooterWrapper = styled.div`
    width: 100%;
    border-top: 1px solid ${africredColors.neutrals.night[200]};
    display: flex;
    padding: 16px;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    background-color: ${brandTheme.brand.backgroundSkin};
`
const StepFooter = ({ goToNext, className, loading }) => {
    return (
        <FooterWrapper
            className={`flex justify-between w-full sm:w-2/3 gap-4 rounded pt-8 pb-8 ${
                className || 'lg:w-2/4 xl:w-2/5'
            }`}
        >
            <Button
                onClick={() => goToNext({ id: 1 })}
                width="40%"
                size="md"
                bgcolor={brandTheme.brand.secondary}
            >
                {loading ? (
                    <Spinner />
                ) : (
                    <Text type="p" weight="bold" color="white">
                        Continue
                    </Text>
                )}
            </Button>
        </FooterWrapper>
    )
}

export const OnboardStudent = () => {
    const [onboardingData, setOnboardingData] = useState({
        loading: false,
        accountType: 'Agent',
        useCase: ['Short Term Loan'],
        firstName: '',
        lastName: '',
        gender: '',
        phoneNumber: '',
        countryCode: '',
        address: {
            country: '',
            state: '',
            city: '',
            zip: '',
        },
    })
    const [currentIndex, setCurrentIndex] = useState(0)
    // eslint-disable-next-line no-unused-vars
    const [stepCount, setStepCount] = useState(null)
    const axiosPrivate = useAxiosPrivate()
    const { setAuth } = useContext(AuthContext)
    const navigate = useNavigate()
    const auth = useAuth()
    const [verifyPhone, setVerifyPhone] = useState(false)
    const [otpID, setOTPId] = useState()

    async function sendOtp() {
        if (onboardingData.loading) {
            return
        }
        setOnboardingData(prev => ({
          ...prev,
          loading: true
        }));
        try {
            const axiosApi = axiosPrivate

            const response = await axiosApi.post(
                `/account/send-phone-number-otp`,
                {
                    phoneNumber: onboardingData.phoneNumber,
                },
            )

            if (response.data) {
                setOTPId(response.data.otp.id)
                toast.success('Phone OTP sent')
                setVerifyPhone(true)
                setOnboardingData(prev => ({
                  ...prev,
                  loading: false
                }));
            }
        } catch (err) {
            toast.error(err.response.data.message)
        }

        setOnboardingData(prev => ({
            ...prev,
            loading: false,
        }))
    }

    async function setOTPSuccess() {
        onFinish(onboardingData, true)
    }

    useEffect(() => {
      if (auth.kycStatus) {
        navigate("/dashboard", { replace: true });
      }
    }, [auth.kycStatus, navigate]);

    const onNext = async (stepData, numberOfSteps) => {
        await setOnboardingData(prev => ({ ...prev, ...stepData }))
        if (currentIndex < numberOfSteps) setCurrentIndex(currentIndex + 1)
    }
    const onFinish = async (stepData, done) => {
        // if (!done) {
        //     sendOtp()
        //     return
        // }

        if (onboardingData.loading) {
            return
        }
        setOnboardingData(prev => ({
          ...prev,
          loading: true
        }));
        const payload = {
            ...stepData,
            phoneNumber: separatePhoneNumberString(
                stepData?.phoneNumber,
                stepData.countryCode,
            ),
        }

        try {
            const response = await axiosPrivate.patch('/account/kyc', payload)

            // Set the auth data here e.g., setAuth({ user, pwd, roles, accessToken });
            if (response.data) {
                setAuth(response.data)

                // navigate to the route from whence the user came or home.
                navigate('/dashboard')
            }
        } catch (err) {
            toast.error(err.response.data.message)
        }

        setOnboardingData(prev => ({
          ...prev,
          loading: false
        }));
    }

    return (
        <>
            {!verifyPhone ? (
                <Wrapper>
                    <OnboardingFlow
                        currentIndex={currentIndex}
                        onFinish={onFinish}
                        onNext={onNext}
                        getStepCount={setStepCount}
                    >
                        <StepOne
                            data={onboardingData}
                            setData={setOnboardingData}
                        />
                        <StepTwo
                            data={onboardingData}
                            setData={setOnboardingData}
                        />
                    </OnboardingFlow>
                </Wrapper>
            ) : (
                <OTPComponent
                    id={otpID}
                    setOTPSuccess={() => {}}
                    useCase="changephone"
                    phoneNumber={onboardingData.phoneNumber}
                    countryCode={onboardingData.countryCode}
                    onSuccess={() => onFinish(onboardingData, true)}
                />
            )}
        </>
    )
}

function StepOne({ goToNext, data, goBack, setData }) {
    const auth = useAuth();
    const descriptions = [
        "Apply & Get Admission from 300+ top Universities across USA, Canada, UK, EU, UAE and more",
        "Already have an admit? Apply for Education Loan & Living Cost Funding",
        "Need to pay University Acceptance Fees? Get a Loan in 48 Hours",
        "Pay Tuition Fees Globally & Get Rewarded | Also ordering to be as per numbers"
    ]

    return (
        <>
            <StudentOnboardTemplate className="w-full md:w-4/6">
                <div>
                    <Text type="h5" className="mb-5">
                        How do you want to be identified?
                    </Text>
                    <div className="flex flex-col md:flex-row gap-4 mb-8">
                        <CustomRadioInput
                            value="Student/Guardian"
                            name="accountType"
                            checked={data.accountType === 'Student/Guardian'}
                            onChange={e =>
                                setData(prev => ({
                                    ...prev,
                                    accountType: 'Student/Guardian',
                                }))
                            }
                            description="A person who is studying at a university or other place of higher education."
                        />
                        {/* <CustomRadioInput
              value="Guardian"
              name="accountType"
              checked={data.accountType === "Guardian"}
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  accountType: "Guardian",
                }))
              }
              description="A parent or someone with legal responsibility to care for a child or an adult who need self-care"
            /> */}
                        <CustomRadioInput
                            value="Institute"
                            name="accountType"
                            checked={data.accountType === 'Agent'}
                            onChange={e =>
                                setData(prev => ({
                                    ...prev,
                                    accountType: 'Agent',
                                }))
                            }
                            description="A person or institution who is representing any student looking to travel abroad for educational purpose."
                        />
                    </div>

                    <Text type="h5" className="mb-5">
                        What will you mostly use ProU for?
                    </Text>

                    <div className="w-full flex flex-col gap-4 mb-4">
                        {auth.enumValues.useCaseEnum.map((item, index) => (
                            <CustomCheckboxInput
                                key={item}
                                height="100px"
                                value={item}
                                name="useCase"
                                checked={data.useCase.includes(item)}
                                onChange={e => {
                                    if (data.useCase.includes(e.target.value)) {
                                        setData(prev => {
                                            const newUseCaseArray = [
                                                ...prev.useCase,
                                            ]
                                            newUseCaseArray.splice(
                                                data.useCase.indexOf(
                                                    e.target.value,
                                                ),
                                                1,
                                            )
                                            return {
                                                ...prev,
                                                useCase: newUseCaseArray,
                                            }
                                        })
                                    } else {
                                        setData(prev => ({
                                            ...prev,
                                            useCase: [
                                                ...prev.useCase,
                                                e.target.value,
                                            ],
                                        }))
                                    }
                                }}
                                description={descriptions[index]}
                            />
                        ))}
                    </div>
                </div>
            </StudentOnboardTemplate>
            <StepFooter goToNext={() => goToNext(data)} />
        </>
    )
}

export const StepTwo = ({ data, onFinish, goBack, setData }) => {
    const auth = useAuth()
    const [isPhoneValid, setIsPhoneValid] = useState(false)

    return (
        <>
            <StudentOnboardTemplate>
                <FormInputs>
                    <FormRow>
                        <Input
                            icon={<Icon type="person" />}
                            type="text"
                            size="md"
                            placeholder="First Name"
                            autoComplete="off"
                            value={data.firstName}
                            onChange={e => {
                                setData(prev => ({
                                    ...prev,
                                    firstName: e.target.value,
                                }))
                            }}
                        />
                        <Input
                            icon={<Icon type="person" />}
                            type="text"
                            size="md"
                            placeholder="Last Name"
                            autoComplete="off"
                            value={data.lastName}
                            onChange={e => {
                                setData(prev => ({
                                    ...prev,
                                    lastName: e.target.value,
                                }))
                            }}
                        />
                    </FormRow>

                    <Input
                        size="md"
                        type="phone"
                        value={data?.phoneNumber}
                        onChange={(val, country) =>
                            setData(prev => ({
                                ...prev,
                                phoneNumber: val,
                                countryCode: country.countryCode,
                            }))
                        }
                        setIsPhoneValid={setIsPhoneValid}
                    />

                    <FormRow>
                        <AntdSelect
                            options={formatSelectOptionForAntd([
                                'Male',
                                'Female',
                                'Not Specified',
                            ])}
                            value={data.gender}
                            placeholder="Select gender"
                            setValue={val =>
                                setData(prev => ({ ...prev, gender: val }))
                            }
                        />
                    </FormRow>

                    <FormRow>
                        <Input
                            type="text"
                            size="md"
                            padding="12px"
                            placeholder="City"
                            autoComplete="off"
                            value={data.address.city}
                            onChange={e => {
                                setData(prev => ({
                                    ...prev,
                                    address: {
                                        ...prev.address,
                                        city: e.target.value,
                                    },
                                }))
                            }}
                        />
                        <Input
                            type="text"
                            size="md"
                            padding="12px"
                            placeholder="Postal/Zip Codes"
                            autoComplete="off"
                            value={data.address.zip}
                            onChange={e => {
                                setData(prev => ({
                                    ...prev,
                                    address: {
                                        ...prev.address,
                                        zip: e.target.value,
                                    },
                                }))
                            }}
                        />
                    </FormRow>

                    <Input
                        type="text"
                        size="md"
                        padding="12px"
                        placeholder="State"
                        autoComplete="off"
                        value={data.address.state}
                        onChange={e => {
                            setData(prev => ({
                                ...prev,
                                address: {
                                    ...prev.address,
                                    state: e.target.value,
                                },
                            }))
                        }}
                    />

                    <AntdSelect
                        showSearch
                        options={formatSelectOptionForAntd(
                            auth?.enumValues?.countryEnum,
                        )}
                        placeholder="Country"
                        value={data.address.country}
                        setValue={val =>
                            setData(prev => ({
                                ...prev,
                                address: { ...prev.address, country: val },
                            }))
                        }
                    />
                </FormInputs>
            </StudentOnboardTemplate>
            <StepFooter
                loading={data.loading}
                goToNext={() => {
                    if (!isPhoneValid) {
                        toast.error('Please check phone number')
                    } else if (
                        !(
                            data.firstName &&
                            data.lastName &&
                            data.gender &&
                            data.address?.state &&
                            data.address?.country
                        )
                    ) {
                        toast.error('Please fill in the provided fields')
                    } else {
                        onFinish(data)
                    }
                }}
            />
        </>
    )
}
