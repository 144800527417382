import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import bgImage from '../../../assets/pngs/hero-image.png'
import { Button, Icon, Text } from '../../atoms'
import { africredColors } from '../../../theme/colors'
import { Link, useNavigate } from 'react-router-dom'
import { screen } from '../../../theme'
import { RequestInfoModal } from '../../molecules/Modal/RequestInfoModal'
import { CustomRadioInput } from '../../molecules'
import useAuth from '../../../hooks/useAuth'
import { AppNav } from '../../molecules/AppNav'

const LeftSide = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const TitleText = styled(Text)`
    font-size: 2.5rem;
    font-weight: 700;
    line-height:1;
    text-align:center;
    @media only screen and (${screen.sm}) {
        text-align:left;
        font-size:3.5rem;

    }
`
// style={matchMedia(screen.md)? {fontSize: "0.5rem", fontWeight:700, textAlign:"center", lineHeight:"3rem"}: {fontSize: "2.5rem", fontWeight:500, textAlign:"left"}}

export const Home = () => {
    const [openModal, setOpenModal] = useState(false)
    const [application, setApplication] = useState('loan')
    const auth = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        if (auth?.id) {
            navigate('/dashboard')
        }
    }, [auth?.id, navigate])

    return (
        <div className="flex flex-col">
            <AppNav />
            <div className="flex pb-5 md:px-16 lg:px-28">
                <LeftSide className="w-full lg:w-3/6">
                    <div className="flex flex-col gap-8 px-4 md:mt-0">
                        <Text
                            className="w-full md:w-3/4"
                            type="h2"
                            fontSize="2.5rem"
                            style={matchMedia(screen.sm)?{fontWeight:900, lineHeight:"3.5rem"}:{}}
                        >
                            Kick-start your journey toward a brighter future. 🚀
                        </Text>
                        <div className="flex flex-col gap-4">
                            <div className="flex items-center w-full md:w-3/4 gap-4">
                                <div>
                                    <Icon type="checked-bullet" />
                                </div>
                                <Text
                                    type="p"
                                    color="#5D5D5D"
                                    hovercolor="#5D5D5D"
                                >
                                    Apply from curated universities with
                                    pre-approved USD loans based on your profile
                                </Text>
                            </div>
                            <div className="flex items-center w-full md:w-3/4 gap-4">
                                <div>
                                    <Icon type="checked-bullet" />
                                </div>
                                <Text
                                    type="p"
                                    color="#5D5D5D"
                                    hovercolor="#5D5D5D"
                                >
                                    Choose from USA, UK, Canada, Europe and
                                    Australia as study destination
                                </Text>
                            </div>
                            <div className="flex items-center w-full md:w-3/4 gap-4">
                                <div>
                                    <Icon type="checked-bullet" />
                                </div>
                                <Text
                                    type="p"
                                    color="#5D5D5D"
                                    hovercolor="#5D5D5D"
                                >
                                    End-to-end application from application to
                                    loan to visa
                                </Text>
                            </div>

                            <div className="flex flex-col gap-2 mt-4 mb-4">
                                <Text
                                    type="p"
                                    color="#5D5D5D"
                                    hovercolor="#5D5D5D"
                                >
                                    Haven&apos;t started your university
                                    application yet?
                                </Text>

                                <div className="w-full max-w-[430px] flex flex-col sm:flex-row gap-2 sm:border-[1px] border-[#c7d4fe] rounded-full mt-3 p-2">
                                    <Link
                                        to="/universities"
                                        className="w-full md:w-1/2"
                                    >
                                        <Button
                                            className={`w-full h-12 rounded-full bg-white hover:bg-[${africredColors.primary.blue[600]}] text-[${africredColors.primary.blue[600]}] hover:text-white text-sm`}
                                            border={`1px solid ${africredColors.primary.blue[600]}`}
                                        >
                                            <Text
                                                type="p"
                                                weight="regular"
                                                textWrap="nowrap"
                                                fontSize="1rem"
                                            >
                                                Explore Universities
                                            </Text>
                                        </Button>
                                    </Link>
                                    <Button
                                        className={`w-full md:w-1/2 h-12 rounded-full bg-[${africredColors.primary.blue[600]}] hover:bg-white text-white hover:text-[${africredColors.primary.blue[600]}]`}
                                        border={`1px solid ${africredColors.primary.blue[600]}`}
                                        onClick={() => setOpenModal(true)}
                                    >
                                        <Text
                                            type="p"
                                            weight="regular"
                                            textWrap="nowrap"
                                            fontSize="1rem"
                                        >
                                            Get Started Now
                                        </Text>
                                        <Icon type="arrow-right" />
                                    </Button>
                                </div>
                                <div className="flex flex-wrap md:flex-nowrap gap-4 mt-4">
                                    <Text
                                        type="p"
                                        color="#5D5D5D"
                                        hovercolor="#5D5D5D"
                                    >
                                        Already created an account?
                                    </Text>                
                                    <Text
                                        type="p"
                                        weight="bold"
                                        color={africredColors.primary.blue[600]}
                                    >
                                        <Link to="/student-login"> Student Login</Link>
                                    </Text>
                                    <Text
                                        type="p"
                                        weight="bold"
                                        color={africredColors.primary.blue[600]}
                                    >
                                        <Link to="/agent-login"> Institute Login</Link>
                                    </Text>

                                </div>
                            </div>
                        </div>
                    </div>
                </LeftSide>
                <div className="hidden lg:flex md:w-3/6 justify-center">
                    <img src={bgImage} alt="background" />
                </div>
            </div>

            <RequestInfoModal open={openModal} setOpen={setOpenModal}>
                <ModalContent className="min-h-[95vh] flex flex-col items-center justify-center gap-6">
                    <div className="flex flex-col items-center gap-4  mb-0 md:mb-8 w-full md:w-3/4 md:self-start">
                        <TitleText>
                            Get Started with your application
                        </TitleText>
                        <Text type="p" textalign="center" className={`mt-0 md:mt-2`}>
                            We are here to guide you each step along the way
                        </Text>
                    </div>
                    <div className="flex flex-col gap-6 mb-4">
                        <CustomRadioInput
                            value="Start Loan Application"
                            name="accountType"
                            checked={application === 'loan'}
                            onChange={e => setApplication('loan')}
                            description="Choose this if you already have an offer letter"
                            height="auto"
                        />
                        <CustomRadioInput
                            value="University admission"
                            name="accountType"
                            checked={application === 'admission'}
                            onChange={e => setApplication('admission')}
                            description="We will help you get offer letter. you can apply for loan afterwards."
                            height="auto"
                        />

                        <ModalFooter
                            to={
                                application === 'loan'
                                    ? '/apply-for-loan'
                                    : '/apply-for-admission'
                            }
                        >
                            <Button
                                onClick={() => {}}
                                size="md"
                                width="100%"
                                bgcolor={africredColors.primary.blue[600]}
                            >
                                <Text type="p" color="#fff">
                                    Apply now
                                </Text>
                            </Button>
                        </ModalFooter>
                    </div>
                    
                </ModalContent>
            </RequestInfoModal>
        </div>
    )
}

const ModalContent = styled.div`
    position: relative;
`
const ModalFooter = styled(Link)`
    width: 100%;
    margin-bottom:15px;
`
