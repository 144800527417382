import React, { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import styled from 'styled-components'
import { AntdSelect, Button, Icon, Input, Text } from '../../atoms'
import {
    formatSelectOptionForAntd,
    getReponsiveWidth,
    matchesQuery,
} from '../../../utils'
import { africredColors } from '../../../theme/colors'
import { Link, useNavigate } from 'react-router-dom'
import logo from '../../../assets/svgs/full_logo.svg'
import { screen } from '../../../theme'
import axios from 'axios'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { toast } from 'react-toastify'
import useAuth from '../../../hooks/useAuth'
import useDebounce from '../../utils/useDebounce'

const Wrapper = styled.div`
    background-color: ${africredColors.neutrals.night['100']};
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

const Content = styled.div`
    background-color: white;
    width: 80%;
    // height:${getReponsiveWidth(700)};
    margin-top: ${getReponsiveWidth(87)};
    @media only screen and (${screen.md}) {
        width: ${getReponsiveWidth(690)};
    }
`
const FormSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${getReponsiveWidth(16)};
`
const Header = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: ${getReponsiveWidth(24)};
    @media only screen and (${screen.sm}) {
        flex-direction: row;
    }
`
const Row = styled.div`
    display: flex;
    gap: ${getReponsiveWidth(16)};
`

const FormRow = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    > div {
        width: 100%;
    }

    @media only screen and (${screen.md}) {
        flex-direction: row;
    }
`

const WithdrawWrapper = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    padding: ${({ padding }) => padding || '18px 16px'};
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: ${({ borderradius }) => borderradius || '16px'};
    border: 1px solid #d1d1d1;
    background-color: ${africredColors.neutrals.night[80]};
`
const FormField = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${getReponsiveWidth(16)};
    width: 100%;
`

const SuccessWrapper = styled.div`
    width: ${getReponsiveWidth(731)};
`

const SuccessHeader = styled.div`
    width: 100%;
    margin-bottom: ${getReponsiveWidth(32)};
    display: flex;
    justify-content: center;
`

const SuccessBody = styled.div`
    padding: ${getReponsiveWidth(48)};
    background-color: ${africredColors.neutrals.white['10']};
    display: flex;
    flex-direction: column;
`
const LogoWrapper = styled.div`
    display: none;
    @media only screen and (${screen.md}) {
        display: block;
    }
`
const Image = styled.img`
    width: ${getReponsiveWidth(136)};
    height: ${getReponsiveWidth(42)};
    @media only screen and (${screen.md}) {
        width: 100%;
    }
`

export const WidthdrawalRequest = () => {
    const auth = useAuth()
    const axiosPrivate = useAxiosPrivate()
    const usdCurrency = {
        icon: 'https://afri-cred.s3.eu-west-2.amazonaws.com/flags/Richoco+Studio++Samples/US+-+United+States.png',
        value: 'USD',
    }
    const [data, setData] = useState({
        currency: 'EUR',
        accountNumber: null,
        bankCode: null,
        amount: '',
        amountToWithdrawInCurrency: null,
        conversionRate: null,
        narration: null,
    })
    const [validateAccount, setValidateAccount] = useState()
    const [isValidatingAccount, setIsValidatingAccount] = useState(false)
    const toSearch = useDebounce(data.accountNumber, 1000)
    const [isSuccessful, setIsSuccessful] = useState(false)
    const navigate = useNavigate()
    const { data: bankList, isLoading } = useQuery({
        queryKey: ['bankList', data?.currency],
        queryFn: async () => {
            const response = await axiosPrivate.post(`/budpay/bank-list`, {
                currency: data?.currency,
            })

            if (response.data) {
                return response.data
            }
        },
        // enabled: !!data.currency,
    })

    useEffect(() => {
        ;(async () => {
            if (
                data.currency &&
                data.bankCode &&
                toSearch &&
                toSearch.length > 8
            ) {
                const dataToSend = {
                    currency: data?.currency,
                    bankCode: data?.bankCode,
                    accountNumber: toSearch,
                }
                setIsValidatingAccount(true)
                setValidateAccount(null)
                await axiosPrivate
                    .post(`/budpay/account-name-verification`, dataToSend)
                    .then(response => {
                        setValidateAccount(response.data?.data)
                    })
                    .catch(_err => {
                        setValidateAccount(null)
                    })
                setIsValidatingAccount(false)
            } else {
                setValidateAccount(undefined)
            }
        })()
    }, [toSearch, data.currency, data.bankCode])

    useEffect(() => {
        const calculateFee = async ({
            amount,
            transactionType,
            currency,
            currencyToPay,
        }) => {
            try {
                const response = await axiosPrivate.get(
                    `/app-configuration/processing-fee/${
                        amount || 0
                    }/${currency}/${transactionType}/${currencyToPay}`,
                )

                if (response.data) {
                    setData(prev => ({
                        ...prev,
                        conversionRate: response.data.remittanceInCurrencyToPay,
                    }))
                }
            } catch (e) {
                if (
                    e?.response?.data?.statusCode !== 500 &&
                    e?.response?.data?.statusCode !== 404
                ) {
                    toast.error(e?.response?.data?.message)
                }
            }
        }
        if (data.currency) {
            calculateFee({
                amount: 1,
                transactionType: 'Remittance',
                currency: usdCurrency.value,
                currencyToPay: data?.currency,
            })
        }
    }, [data.currency])

    useEffect(() => {
        if (data.conversionRate && data.amount) {
            setData(prev => ({
                ...prev,
                amountToWithdrawInCurrency:
                    Number(data.amount) * data.conversionRate,
            }))
        }
    }, [data.conversionRate, data.amount])

    const handleWithdrawalRequest = async () => {
        if (!validateAccount) {
            return toast.error('Please provide the necessary valid information')
        }
        if (!data.amount) {
            return toast.error('Please provide a valid amount')
        }
        try {
            const bankName = getBankNameFromCode(bankList.data, data?.bankCode)
            const payload = {
                country: data?.currency,
                bankName: bankName,
                fullName: validateAccount,
                accountNumber: data?.accountNumber,
                amountInUsd: data?.amount,
                amountToWithdrawInCurrency: data?.amountToWithdrawInCurrency,
                narration: data.narration,
            }
            const response = await axiosPrivate.post(`/payout/create`, payload)
            if (response?.data) {
                toast.success(` Payout request was successful`)
                // navigate('/earnings')
                setIsSuccessful(true)
            }
        } catch (err) {
            toast.error(err?.response?.data?.message)
        }
    }
    return (
        <Wrapper>
            {!isSuccessful ? (
                <>
                    <Content
                        className={`p-12 rounded-[${getReponsiveWidth(32)}]`}
                    >
                        <Header>
                            <Text type="h4">Withdrawal Request</Text>
                            <div className="flex flex-col">
                                <Text type="p"> Current Balance</Text>

                                <div className="flex gap-[10px]">
                                    <div className="rounded-full overflow-clip h-6 w-6">
                                        <Icon type="us-flag" />
                                    </div>
                                    <Text type="p"> USD</Text>
                                    <Text type="p" weight="medium">
                                        {' '}
                                        $
                                        {auth?.currentEarnings > 1000
                                            ? `${auth?.currentEarnings / 1000}K`
                                            : auth?.currentEarnings}
                                    </Text>
                                </div>
                            </div>
                        </Header>
                        <FormSection>
                            <FormField>
                                <Text type="p" weight="medium">
                                    {' '}
                                    Currency
                                </Text>
                                <AntdSelect
                                    showSearch
                                    options={formatSelectOptionForAntd([
                                        'EUR',
                                        'AED',
                                        'GBP',
                                    ])}
                                    placeholder="choose a currency"
                                    value={data.currency}
                                    setValue={val => {
                                        setData(prev => ({
                                            ...prev,
                                            currency: val,
                                            bankCode: null,
                                        }))
                                    }}
                                />
                            </FormField>
                            <FormField>
                                <FormField className={``}>
                                    <Text type="p" weight="medium">
                                        {' '}
                                        Select Bank
                                    </Text>
                                    <AntdSelect
                                        showSearch
                                        otherProps={{ disabled: isLoading }}
                                        options={getBankSelectItems(
                                            data?.currency
                                                ? bankList?.data
                                                : [] || [],
                                        )}
                                        placeholder={
                                            isLoading
                                                ? 'loading'
                                                : 'Select Bank'
                                        }
                                        value={data.bankCode}
                                        setValue={val => {
                                            setData(prev => ({
                                                ...prev,
                                                bankCode: val,
                                            }))
                                        }}
                                    />
                                </FormField>
                                <FormField className={``}>
                                    <Text type="p" weight="medium">
                                        {' '}
                                        Account Number
                                    </Text>
                                    <Input
                                        type="text"
                                        size="md"
                                        placeholder="Account Number"
                                        autoComplete="off"
                                        value={data.accountNumber}
                                        onChange={e => {
                                            const val = e.target.value
                                            if (!validateAccountNumber(val))
                                                return
                                            setData(prev => ({
                                                ...prev,
                                                accountNumber: e.target.value,
                                            }))
                                        }}
                                        style={
                                            matchesQuery(screen.sm)
                                                ? {}
                                                : { padding: '2px' }
                                        }
                                    />
                                </FormField>
                            </FormField>

                            {(validateAccount === null ||
                                validateAccount ||
                                isValidatingAccount) && (
                                <Row
                                    className={` rounded-2xl justify-center items-center h-fit bg-[${
                                        isValidatingAccount
                                            ? africredColors.primary.gold['50']
                                            : validateAccount === null
                                            ? africredColors.secondary.red[
                                                  '100'
                                              ]
                                            : africredColors.primary.green['50']
                                    }] p-[${getReponsiveWidth(12)}] `}
                                >
                                    {isValidatingAccount ? (
                                        <Icon type="pending-loading" />
                                    ) : validateAccount === null ? (
                                        <Icon type="info-regular" />
                                    ) : (
                                        <Icon type="check-circle" />
                                    )}
                                    <Text
                                        type="small"
                                        weight="bold"
                                        color={`${africredColors.neutrals.night['500']}`}
                                    >
                                        {isValidatingAccount
                                            ? 'verifying account details'
                                            : validateAccount === null
                                            ? 'Error validating account'
                                            : ` ${validateAccount}`}
                                    </Text>
                                </Row>
                            )}
                            <FormField>
                                <Text type="p" weight="medium">
                                    {' '}
                                    Amount to Widthdraw
                                </Text>
                                <WithdrawWrapper>
                                    <CountryFlag
                                        country={usdCurrency.icon}
                                        currency={usdCurrency.value}
                                    />
                                    <div className="flex flex-col items-end">
                                        <Text type="small" textalign="right">
                                            Actual Amount
                                        </Text>
                                        <Input
                                            type="text"
                                            bgcolor="transparent"
                                            className={`text-right`}
                                            // size="md"
                                            placeholder="0.00"
                                            autoComplete="off"
                                            value={data.amount}
                                            onChange={e => {
                                                const val = e.target?.value
                                                if (isNaN(val)) {
                                                    return
                                                }
                                                setData(prev => ({
                                                    ...prev,
                                                    amount: val,
                                                }))
                                            }}
                                            style={{
                                                padding: 0,
                                                height: 'unset',
                                                outline: 'none',
                                                borderRadius: 0,
                                            }}
                                        />
                                    </div>
                                </WithdrawWrapper>
                            </FormField>
                            {data.conversionRate && (
                                <Row
                                    className={` rounded-2xl items-center h-fit bg-[${
                                        africredColors.secondary.purple['50']
                                    }] p-[${getReponsiveWidth(12)}] `}
                                >
                                    <Icon type="info-regular" />
                                    <Text
                                        type="small"
                                        weight="bold"
                                        color={`${africredColors.neutrals.night['500']}`}
                                    >
                                        Current rate: $1 = {data.currency}{' '}
                                        {data.conversionRate}
                                    </Text>
                                </Row>
                            )}
                            {data.conversionRate && data.amount && (
                                <Row
                                    className={` rounded-2xl items-center h-fit bg-[${
                                        africredColors.primary.blue[100]
                                    }] p-[${getReponsiveWidth(12)}] `}
                                >
                                    <Icon type="info-regular" />
                                    <Text
                                        type="small"
                                        weight="bold"
                                        color={`${africredColors.neutrals.night['500']}`}
                                    >
                                        Total: {data.currency}{' '}
                                        {Number(
                                            data.amountToWithdrawInCurrency,
                                        ).toLocaleString()}
                                    </Text>
                                </Row>
                            )}
                            <FormField>
                                <Text type="p" weight="medium">
                                    {' '}
                                    Narration (Optional)
                                </Text>
                                <Input
                                    type="text"
                                    size="md"
                                    placeholder="Narration"
                                    autoComplete="off"
                                    value={data.narration}
                                    onChange={e =>
                                        setData(prev => ({
                                            ...prev,
                                            narration: e.target.value,
                                        }))
                                    }
                                />
                            </FormField>
                        </FormSection>
                    </Content>

                    <div
                        className={`flex flex-col gap-2 sm:flex-row  sm:justify-between mt-[${getReponsiveWidth(
                            32,
                        )}] w-[${getReponsiveWidth(
                            690,
                        )}] mb-[${getReponsiveWidth(87)}]`}
                    >
                        <Button
                            size="sm"
                            border={`1px solid ${africredColors.neutrals.night[950]}`}
                            onClick={() => navigate('/dashboard')}
                        >
                            <Text type="p" weight="medium">
                                Cancel
                            </Text>
                        </Button>

                        <Button
                            size="sm"
                            border={`1px solid ${africredColors.primary.blue['700']}`}
                            bgcolor={`${africredColors.primary.blue['700']}`}
                            onClick={handleWithdrawalRequest}
                            style={
                                matchesQuery(screen.sm)
                                    ? {}
                                    : { padding: '2px' }
                            }
                        >
                            <Text
                                type="p"
                                weight="medium"
                                color="#fff"
                                style={
                                    matchesQuery(screen.sm)
                                        ? {}
                                        : { fontSize: '12px' }
                                }
                            >
                                Create Request
                            </Text>
                        </Button>
                    </div>
                </>
            ) : (
                <SuccessWrapper>
                    <SuccessHeader>
                        <LogoWrapper>
                            <Link to="/dashboard">
                                <Image src={logo} alt="logo" />
                            </Link>
                        </LogoWrapper>
                    </SuccessHeader>
                    <SuccessBody className="flex flex-col items-center">
                        <div className={`mb-[${getReponsiveWidth(16)}]`}>
                            <Text
                                type="h3"
                                weight="medium"
                                style={{
                                    fontSize: getReponsiveWidth(50.5),
                                    width: getReponsiveWidth(476),
                                    textAlign: 'center',
                                }}
                            >
                                Withdrawal Requested Successfully
                            </Text>
                        </div>
                        <div className={`mb-[${getReponsiveWidth(48)}]`}>
                            <Text
                                type="p"
                                style={{
                                    fontSize: getReponsiveWidth(16),
                                    textAlign: 'center',
                                }}
                            >
                                A hassle-free education loan processing and
                                global education fees remittance with the best
                                rates available from the global markets.
                            </Text>
                        </div>
                        <Link to="/">
                            <Button
                                size="sm"
                                width={getReponsiveWidth(440)}
                                border={`1px solid ${africredColors.primary.blue['700']}`}
                                bgcolor={`${africredColors.primary.blue['700']}`}
                            >
                                <Text type="p" weight="medium" color="#fff">
                                    Proceed to dashboard
                                </Text>
                            </Button>
                        </Link>
                    </SuccessBody>
                </SuccessWrapper>
            )}
        </Wrapper>
    )
}

function CountryFlag({ country, currency }) {
    if (!country) {
        country =
            'https://th.bing.com/th/id/R.067f7bad1bf48631ec7743ac1dec086f?rik=23KOzvBuYTRJPA&riu=http%3a%2f%2fimg1.wikia.nocookie.net%2f__cb20110529184849%2fusnw%2fimages%2f8%2f8b%2fPlaceholder_flag.png&ehk=ePhmjTY3X4FCyT2lCetvagb6l0lD%2bSs%2ftLmtrmf3cn4%3d&risl=&pid=ImgRaw&r=0'
    }
    return (
        <div className="flex items-center h-100 gap-2">
            <div className="rounded-full overflow-clip h-6 w-6">
                {/* <Icon type="us-flag" /> */}
                <img className={`w-full h-full`} src={country} alt="flg" />
            </div>
            <Text type="p">{currency}</Text>
        </div>
    )
}

function getBankSelectItems(banks) {
    return (
        banks?.map(item => ({
            label: item?.bank_name,
            value: item?.bank_code,
        })) || []
    )
}

function validateAccountNumber(accountNumber) {
    if (!accountNumber) return true
    if (accountNumber && +accountNumber === 'NaN') return false
    // if (+accountNumber != Math.floor(+accountNumber)) return false
    // return true
    let regex = /^[0-9]+$/
    if (regex.test(accountNumber)) {
        return true
    }
    return false
}

function isValidAmount(amount) {
    if (amount && +amount === 'NaN') return false
    const regex = /^\d+(\.\d{1,2})?$/
    if (!amount) return true
    // Test if the input matches the regex
    return regex.test(amount)
}

function getBankNameFromCode(bankList, bankCode) {
    let bankName = null
    for (let i = 0; i < bankList.length; i++) {
        if (bankList[i].bank_code === bankCode) {
            bankName = bankList[i].bank_name
            break
        }
    }

    return bankName
}
